import {
    useState,
    useEffect,
    useRef,
    useMemo,
    memo,
    forwardRef,
    PropsWithChildren,
    SyntheticEvent,
} from 'react'
// import util
import { randomKey, classNames } from '@utils'
/* types */
import { ROLE_SELECT_MAP, SELECT_POSITION } from '@types'
/* assets */
import { ArrowDownIcon } from '@assets'
/* styles */
import './index.scss'

const Select = forwardRef<
    HTMLInputElement,
    PropsWithChildren<{
        label?: string
        className?: string
        options: Array<{
            title: string
            value: string | number
            icon?: any
            subtitle?: string
        }>
        selectedValue?: string
        selectedValueTitle?: string
        onChange?: (e: any) => void
        role?: ROLE_SELECT_MAP
        errorMessage?: string
        hint?: string
        isHintReserved?: boolean
        defaultText: string
        beforeContent?: any
        beforeIcon?: any
        afterIcon?: any
        isBackground?: boolean
        position?: SELECT_POSITION
        disabled?: boolean
        [x: string]: any
    }>
>(
    (
        {
            label,
            className = '',
            options = [],
            selectedValue = null,
            selectedValueTitle = null,
            // eslint-disable-next-line @typescript-eslint/no-empty-function
            onChange = () => {},
            defaultText = '',
            errorMessage,
            hint,
            isHintReserved = false,
            role = ROLE_SELECT_MAP.NONE,
            disabled,
            keepBorder = false,
            isFit = false,
            isInteractive = false,
            beforeContent,
            beforeIcon,
            afterIcon,
            isBackground = false,
            position = SELECT_POSITION.BOTTOM,
            ...rest
        },
        ref
    ) => {
        const [title, setTitle] = useState('')
        const [currentOption, setCurrentOption] = useState<any>()
        const [isActive, setIsActive] = useState(false)

        // random id to assing to input field & make reference from label
        // eslint-disable-next-line @typescript-eslint/no-unsafe-return
        const idSelectField: string = useMemo((): string => randomKey().key, [])
        const SelectFieldRef: any = useRef(null)

        useEffect(() => {
            setTitle(defaultText)
        }, [defaultText])

        // capture click to option item, then, update title select and dispatch onChange function
        const handleClickOptionItem = (value: any) => {
            const currentOption = options.find(o => o.value == value)

            setTitle(currentOption?.title as string)
            setCurrentOption(currentOption)
            onChange({ target: { value } })
            setIsActive(false)
        }

        // Detect focus for component to show options list
        const handleFocus = () => {
            const value = isActive === false ? true : false
            setIsActive(value)
        }

        // Detect blur for component to hide option list
        const handleBlur = (e: SyntheticEvent) => {
            if (SelectFieldRef.current) {
                if (!SelectFieldRef.current.contains(e.target)) {
                    setIsActive(false)
                }
            }
        }

        useEffect(() => {
            // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
            window.addEventListener('click', handleBlur as any)

            // If props of selected item is passed, replace deafult component value to selected item
            if (selectedValue !== null || selectedValueTitle !== null) {
                options.forEach(option => {
                    if (option.value === selectedValue || option.title === selectedValueTitle) {
                        setTitle(option.title)
                    }
                })
            }

            return () => {
                // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
                window.removeEventListener('click', handleBlur as any)
            }
        }, [selectedValue, selectedValueTitle, options])

        return (
            <fieldset
                ref={SelectFieldRef}
                className={classNames(
                    `select`,
                    isActive ? 'select--active' : '',
                    errorMessage ? ROLE_SELECT_MAP.DANGER : role || ROLE_SELECT_MAP[role],
                    isFit ? 'select--fit' : '',
                    className
                )}
                disabled={disabled}
            >
                {
                    // if label property is passed, render the label
                    label ? (
                        <label htmlFor={idSelectField} className="select__label">
                            {label}
                        </label>
                    ) : null
                }

                <div
                    className={classNames(
                        'select__content',
                        keepBorder ? 'select__content--keep' : ''
                    )}
                >
                    {beforeContent && beforeContent(currentOption)}

                    <div
                        ref={SelectFieldRef}
                        className={classNames(
                            'select__content-field',
                            isBackground ? 'select__content-field--bg' : '',
                            disabled ? 'select__content--disabled' : ''
                        )}
                        onClick={handleFocus}
                        onBlur={handleBlur}
                    >
                        {beforeIcon && <img className="select__content-bi" src={beforeIcon} alt='select_bi'/>}

                        {/* ...rest assing other props passed into component invoke */}
                        <input
                            autoComplete="off"
                            id={idSelectField}
                            type="text"
                            value={title}
                            onChange={() => title}
                            onClick={handleFocus}
                            onBlur={handleBlur}
                            readOnly={true}
                            className="select__content__select"
                            ref={ref}
                            {...rest}
                        />

                       {/*  <Tippy
                            arrow={true}
                            interactive={true}
                            trigger="mouseenter focus"
                            //placement="bottom"
                            content={<span>{title}</span>}
                        >
                            <span
                                onClick={handleFocus}
                                onBlur={handleBlur}
                                className="content__select--input"
                                title={title}
                            >
                                <label
                                    //className="content__select--input"
                                    //htmlFor={idSelectField}
                                    //title={title}
                                >
                                    {title}
                                </label>
                            </span>
                        </Tippy> */}

                        <span
                            onClick={handleFocus}
                            onBlur={handleBlur}
                            className="content__select--input"
                            title={title}
                        >
                            <label
                                //className="content__select--input"
                                //htmlFor={idSelectField}
                                //title={title}
                            >
                                {title}
                            </label>
                        </span>

                        <i
                            className={classNames(
                                'select__content-select',
                                disabled ? 'select__content-select--disabled' : ''
                            )}
                        >
                            <ArrowDownIcon />
                        </i>
                    </div>
                </div>

                {/* Render the option list */}
                <ul
                    className={classNames('select__options', position || SELECT_POSITION[position])}
                >
                    {options.map(({ value, title, icon, subtitle }) => (
                        <li
                            {...randomKey()}
                            data-option={value}
                            onClick={() => handleClickOptionItem(value)}
                            className="select__options__item"
                        >
                            {icon && <img className="select__content-bi" src={icon} alt={title} />}

                            <div>
                                <span>{title}</span>
                                {subtitle && <h5>{subtitle}</h5>}
                            </div>
                        </li>
                    ))}
                </ul>

                {errorMessage ? (
                    <span className="select__hint">{errorMessage}</span>
                ) : hint ? (
                    <span className="select__hint">{hint}</span>
                ) : (
                    isHintReserved && <span className="select__reserved" />
                )}
            </fieldset>
        )
    }
)

export default memo(Select)

import { memo } from 'react'
import { DashboardLayout } from '@layouts'
import {
    Backend,
    Frontend,
    Mobile,
    Person4Img,
    Person5Img,
    Person6Img,
    Person7Img,
    Project,
    ProjectManage,
} from '@assets'
import './index.scss'

const AboutUsView = () => {
    const person = [
        {
            icon: Project,
            title: 'Mónica',
            subtitle: (
                <div className="about-us-view__card__subtitle">
                    <h3>Socio Fundador</h3>

                    <div>
                        <span>Economista, especialista en finanzas.</span>
                        <span>Blockchain: Disrupción Tecnológica MIT.</span>
                        <span>
                            Revolución Fintech: Servicios y estrategias financieras transformadoras,
                            The Wharton School.
                        </span>
                    </div>
                </div>
            ),
        },
        {
            icon: ProjectManage,
            title: 'Felipe',
            subtitle: (
                <div className="about-us-view__card__subtitle">
                    <h3>Socio Fundador - CEO</h3>

                    <div>
                        <span>Ingeniero Financiero, especialidad en riesgo.</span>
                        <span>Amplia experiencia en:</span>
                        <span>
                            Modelos financieros, score de crédito, originación de crédito, riesgo,
                            finanzas, medios de pago y banca de inversión.
                        </span>
                    </div>
                </div>
            ),
        },
        {
            icon: Backend,
            title: 'Harold',
            subtitle: (
                <div className="about-us-view__card__subtitle">
                    <h3>Project Manager</h3>

                    <span>
                        Desarrollador fullstack especialista en tecnologías blockchain,
                        criptoactivos, NFTs, plataformas de pago y monitoreo en tiempo real, gestión
                        y administración de recursos de infraestructura tecnológica.
                    </span>
                </div>
            ),
        },
        {
            icon: Mobile,
            title: 'Bryan',
            subtitle: (
                <div className="about-us-view__card__subtitle">
                    <h3>Desarrollador movil</h3>

                    <span>
                        Ingeniero en computación con experiencia en desarrollo de software para
                        dispositivos móviles, desktop y plataforma web, core para sistema de cobros
                        y pagos.
                    </span>
                </div>
            ),
        },
        {
            icon: Frontend,
            title: 'Diana',
            subtitle: (
                <div className="about-us-view__card__subtitle">
                    <h3>Desarrollador frontend</h3>

                    <span>
                        Ingeniero en sistemas con experiencia en desarrollo de software para
                        plataforma web.
                    </span>
                </div>
            ),
        },
    ]
    return (
        <DashboardLayout className="about-us-view" isBackground={true}>
            <main className="about-us-view__main">
                <section>
                    <div className="about-us-view__background">
                        <i className="about-us-view__background--1">
                            <Person4Img />
                        </i>

                        <i className="about-us-view__background--2">
                            <Person6Img />
                        </i>
                        <i className="about-us-view__background--3">
                            <Person5Img />
                        </i>
                        <i className="about-us-view__background--4">
                            <Person7Img />
                        </i>
                    </div>

                    <div className="about-us-view__information">
                        <h1>Quienes somos</h1>

                        <div>
                            <p>
                                En Blocklön, tenemos una misión apasionante: transformar la forma en
                                que el mundo maneja su dinero y vive sus finanzas. Nuestra visión es
                                sencilla pero ambiciosa: queremos que nuestros clientes tengan
                                acceso a un futuro financiero más brillante y sin fronteras. Es por
                                eso que nos enorgullecemos de ser los líderes en la revolución
                                financiera del siglo XXI.
                            </p>

                            <p>
                                ¿Qué nos hace diferentes? En Blocklön, Te brindamos la llave para
                                potencializar tus criptomonedas hacia un mundo de oportunidades
                                financieras.
                            </p>

                            <p>
                                Hemos implementado una forma innovadora de realizar transacciones.
                                Nuestro servicio de procesamiento de pagos con criptomonedas está
                                cambiando las reglas del juego. Tú, como cliente, puedes pagar con
                                tus criptomonedas favoritas, y los comercios pueden recibir moneda
                                nacional. Es una solución eficiente, segura y versátil que
                                simplifica la vida de todos.
                            </p>

                            <p>
                                Créditos con garantía crypto, son fáciles de obtener y están al
                                alcance de tus manos, menos de 15 minutos lo tienes en tu cuenta
                                bancaria, no necesitas comprobante de ingresos ni historial
                                crediticio, buscamos generar un impacto en la inclusión financiera a
                                nivel mundial brindando nuevas soluciones financieras que beneficien
                                a nuestros clientes. Empoderamos y simplificamos la forma en que
                                interactúas con el dinero y las cripto.
                            </p>

                            <p>
                                En Blocklön, te empoderamos y te permitimos aprovechar al máximo tus
                                inversiones en criptomonedas. Ofrecemos liquidez inmediata,
                                transacciones confiables y una transparencia que te permitirá tomar
                                el control de tu futuro financiero.
                            </p>

                            <p>
                                Únete a Blocklön y sé parte de la nueva era de las finanzas. Juntos,
                                alcanzaremos metas que solo parecían un sueño hace poco tiempo. ¡Tu
                                futuro financiero está a solo un clic de distancia!
                            </p>
                        </div>
                    </div>
                </section>

                <section>
                    <div className="about-us-view__container">
                        <h1>Conoce al increíble equipo que hizo esto posible</h1>

                        <div className="about-us-view__cards">
                            {person.map((item, index) => (
                                <div key={index} className="about-us-view__card">
                                    <div className="about-us-view__card__image">
                                        <img src={item.icon} alt={`img-${index + 1}`} />
                                    </div>

                                    <div className="about-us-view__card__title">
                                        <h3>{item.title}</h3>

                                        {item.subtitle}
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                </section>
            </main>
        </DashboardLayout>
    )
}

export default memo(AboutUsView)

/* react */
import { memo } from 'react'
/* components */
import { ThreeDots } from 'react-loader-spinner'

export interface loading {
    height?: string
    width?: string
    color?: string
}

const Loading = ({ height, width, color }: loading) => {
    return (
        <ThreeDots
            height={height ? height : '16'}
            width={width ? width : '50'}
            radius="9"
            color={color ? color : '#000'}
            ariaLabel="three-dots-loading"
            wrapperStyle={{
                justifyContent: 'center',
            }}
            visible={true}
        />
    )
}

export default memo(Loading)

import { FC } from 'react'
import { BrowserRouter as Switch, Routes, Route } from 'react-router-dom'
import { randomKey } from '@utils'
import { AboutUs, ContactView, Home, QuestionsView } from '@views'

const AppRoutes: FC = () => {
    const routes = [
        { path: '/', component: <Home /> },
        { path: '/about-us', component: <AboutUs /> },
        { path: '/frequent-questions', component: <QuestionsView /> },
        { path: '/contact', component: <ContactView /> },
    ]

    return (
        <>
            <Switch>
                <Routes>
                    {routes.map(({ path, component }) => (
                        <Route {...randomKey()} path={path} element={component} />
                    ))}
                </Routes>
            </Switch>
        </>
    )
}
export default AppRoutes

/* react */
import { FC, memo } from 'react'
import { useNavigate } from 'react-router-dom'
/* layouts */
import { ModalLayout } from '@layouts'
/* models */
//import { Country } from '@models'
/* utils */
import { classNames, randomKey } from '@utils'
/* types */
import { MODAL_POSITION, navItems, ROLE_BUTTON_MAP, VARIANT_BUTTON_MAP } from '@types'
/* components */
import { Button, NavLink } from '..'
/* assets */
import { LogoCryplon } from '@assets'
import { MdClose, MdOutlineArrowForwardIos } from 'react-icons/md'
/* styles */
import './index.scss'
import { FaArrowRight } from 'react-icons/fa'

const MobileDropdown: FC<{ isModal: boolean; hideModal: () => void }> = ({
    isModal,
    hideModal,
    ...rest
}) => {
    const navigate = useNavigate()

    // const countries = JSON.parse(sessionStorage.getItem('country')!)

    //const country = countries?.find((c: Country) => c.id == 158)

    return (
        <ModalLayout isModal={isModal} onClose={hideModal} position={MODAL_POSITION.TOP}>
            <div className="mobile-dropdown" {...rest}>
                <header className="mobile-dropdown__title">
                    <div className="mobile-dropdown__logo">
                        <i>
                            <LogoCryplon />
                        </i>
                    </div>

                    <Button
                        className="mobile-dropdown__close"
                        role={ROLE_BUTTON_MAP.INFO}
                        variant={VARIANT_BUTTON_MAP.TEXT}
                        onClick={hideModal}
                    >
                        <i>
                            <MdClose />
                        </i>
                    </Button>
                </header>

                <div className="mobile-dropdown__content">
                    <ul className="mobile-dropdown__options">
                        {navItems.map(({ title, path }) => (
                            <li {...randomKey()}>
                                <NavLink
                                    to={path}
                                    className="mobile-dropdown__option"
                                    activeClassName="mobile-dropdown__option--active"
                                >
                                    <span>{title}</span>

                                    <i>
                                        <MdOutlineArrowForwardIos />
                                    </i>
                                </NavLink>
                            </li>
                        ))}
                    </ul>

                    <div className="mobile-dropdown__divider" />

                    {/* <div className="mobile-dropdown__select">
                        <img src={country?.flag} />

                        <span>{country?.name}</span>
                    </div> */}
                    
                    <a href="https://app.blocklon.com/login">
                        <Button
                            role={ROLE_BUTTON_MAP.WARNING}
                            variant={VARIANT_BUTTON_MAP.FILL}
                            className={classNames('mobile-dropdown__login')}
                        >
                            Ingresar <FaArrowRight />
                        </Button>
                    </a>
                </div>
            </div>
        </ModalLayout>
    )
}

export default memo(MobileDropdown)

import { memo } from 'react'
import { useNavigate } from 'react-router-dom'
import { useForm } from 'react-hook-form'
import { contactServices } from '@services'
import { contact, contactResquest } from '@models'
import { DashboardLayout } from '@layouts'
import { validateText, validationEmail } from '@utils'
import { ROLE_BUTTON_MAP, VARIANT_BUTTON_MAP } from '@types'
import { TextField, Button, TextareaField, showMessage } from '@components'
import { MdOutlineEmail } from 'react-icons/md'
import { HiOutlinePhone } from 'react-icons/hi'
import './index.scss'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'

const ContactView = () => {
    const navigate = useNavigate()
    const {
        register,
        handleSubmit,
        watch,
        trigger,
        setValue,
        reset,
        formState: { errors },
    } = useForm<contact>()

    const onSubmit = handleSubmit((data: contact) => submit(data))

    const submit = async (data: contact) => {
        try {
            const response = (await contactServices(data)) as contactResquest

            if (response.error === true)
                return showMessage({
                    message: 'Hubo un incoveniente intentelo más tarde',
                    type: 'error',
                })

            if (response.data === true) {
                showMessage({
                    message: 'Su consulta se envio exitosamente',
                    type: 'success',
                })

                reset()
            }
        } catch (error) {
            console.log(error)
        }
    }

    return (
        <DashboardLayout className="contact-view" isBackground={true}>
            <main className="contact-view__main">
                <div className="contact-view__title">
                    <h1>Póngase en contacto</h1>

                    <span>
                        En Blocklön nuestros clientes son nuestro principal objetivo, es por esto que
                        ponemos a tu disposición nuestro equipo de soporte las 24 horas los 7 días
                        de la semana.
                    </span>
                </div>

                <div className="contact-view__cards">
                    <div className="contact-view__card">
                        <i>
                            <MdOutlineEmail />
                        </i>

                        <a href="mailto:soporte@blocklon.com">soporte@blocklon.com</a>
                    </div>

                    <div className="contact-view__card">
                        <i>
                            <HiOutlinePhone />
                        </i>

                        <span>+52 8118939984</span>
                    </div>
                </div>

                <form className="contact-view__container" onSubmit={onSubmit}>
                    <div className="contact-view__container__content">
                        <TextField
                            label="Nombre completo"
                            {...register('fullname', {
                                required: {
                                    value: true,
                                    message: 'El nombre es requerido',
                                },
                                minLength: {
                                    value: 8,
                                    message:
                                        'Tiene que ingresar su nombre completo',
                                },
                                onChange: () => trigger('fullname'),
                            })}
                            onKeyDown={validateText}
                            errorMessage={errors.fullname ? errors.fullname.message : ''}
                        />

                        <TextField
                            label="Correo eléctronico"
                            type="email"
                            {...register('email', {
                                required: {
                                    value: true,
                                    message: 'El correo electrónico es requerido',
                                },
                                validate: {
                                    email: v =>
                                        validationEmail(v) ||
                                        'El correo electrónico no tiene un formato válido',
                                },
                                onChange: () => trigger('email'),
                            })}
                            errorMessage={errors.email ? errors.email.message : ''}
                        />
                    </div>

                    <div className="contact-view__container__content">
                        <TextField
                            label="Empresa"
                            {...register('company', {
                                onChange: () => trigger('company'),
                            })}
                            onKeyDown={validateText}
                            errorMessage={errors.company ? errors.company.message : ''}
                        />

                        <TextField
                            label="Asunto"
                            {...register('subject', {
                                required: {
                                    value: true,
                                    message: 'El asunto es requerido',
                                },
                                onChange: () => trigger('subject'),
                            })}
                            onKeyDown={validateText}
                            errorMessage={errors.subject ? errors.subject.message : ''}
                        />
                    </div>

                    <div className="contact-view__container__textarea">
                        <TextareaField
                            //minLenght={12}
                            label="Comentarios o preguntas:"
                            {...register('message', {
                                required: {
                                    value: true,
                                    message: 'El mensaje es requerido',
                                },
                                minLength: {
                                    value: 12,
                                    message:
                                        'El cuerpo del mensaje debe tener minimo 12 caracteres',
                                },
                                /* validate: {
                                    min: (v: any) =>
                                        v.lenght >= 12 ||
                                        'El cuerpo del mensaje debe tener minimo 12 caracteres',
                                }, */
                                onChange: () => trigger('message'),
                            })}
                            //  onKeyDown={validateText}
                            errorMessage={errors.message ? errors.message.message : ''}
                        />
                    </div>

                    <Button role={ROLE_BUTTON_MAP.SUCCESS} variant={VARIANT_BUTTON_MAP.FILL}>
                        Enviar mensaje
                    </Button>
                </form>
            </main>

            <ToastContainer
                position="top-right"
                autoClose={3000}
                hideProgressBar
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
            />
        </DashboardLayout>
    )
}

export default memo(ContactView)
